@import '../../variables';

@media #{$mq-mobile}  {
  .mat-drawer-container {
    background: white;
  }

  mat-sidenav-content {
    overflow-x: hidden !important;
  }

  #dashboard {
    margin: 3% 0;
  }

  .topbar {
    width: 100%;
    display: flex;
    padding: 0 !important;
    align-items: flex-start !important;
    justify-content: center;
    background-color: $primary-color;
    height: auto;

    .mobile_blob {
      width: 98%;
    }

    .logo {
      top: 1.25em;
      z-index: 3;
      width: 100px;
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      z-index: 9999;
    }
  }

  .small-topbar {
    height: 75px;
    background: linear-gradient(180deg, $secondary-color 0%, $primary-color 100%);

    .blob {
      position: absolute;
      right: 0;
      top: 0;
      width: 30%;
    }

    .mat-icon-button {
      margin-left: auto;
      color: #FFFFFF;
      background-color: transparent;
    }

    &__back {
      display: flex;

      mat-icon {
        margin-left: auto;
        color: #FFFFFF;
      }
    }

    &__title {
      color: #FFFFFF;

      span {
        font-weight: bold;
      }
    }
  }

  .welcome {
    background-color: $primary-color;
    text-align: center;
    padding: 0 1.5em .5em 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    #medivus-logo {
      width: 20em;
      height: 3.25em;
      padding: 1em 0 .6875em 0;
    }

    span, .welcome_mobile {
      color: rgba(255, 255, 255, 0.5);
      font-size: $default-font-size * 2;
    }
  }

  #profile {
    .welcome_mobile {
      display: none;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    #avatar {
      border-radius: 100%;
      width: 12.5em;
      height: 12.5em;
      background-color: $third-color;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.125em;

      #image {
        border-radius: 100%;
        width: 93%;
      }
    }

    .information {
      text-align: center;

      .name {
        font-size: $default-font-size * 1.5;
        color: rgba(81, 81, 81, 1);
      }

      .mail {
        font-size: $default-font-size * .75;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  #nav-profile {
    display: flex;
    align-items: center;
    justify-content: space-between;

    mat-icon {
      color: white;
    }

    .name {
      font-size: $default-font-size * 1.5;
      color: white;
      display: flex;
      justify-content: space-between;
    }

    .mail {
      font-size: $default-font-size;
      color: rgba(250, 250, 250, .45);
    }
  }

  .content_area {
    padding-top: 2rem;
    position: relative;
  }

  .container {
    .buttons {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: .5em;
      width: 100%;
      justify-items: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100px;
        height: 100px;
        border-radius: 10px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        color: rgba(255, 255, 255, 0.5);
        border: none;
        background: #C379C9;
        font-size: .5625em;

        mat-icon {
          height: auto;
          width: auto;
          font-size: 9em;
          color: white;
        }
      }
    }
  }

  // Nav
  mat-sidenav {
    width: 100%;
    background: url("../../../../assets/svgs/nav/nav-bg.svg");
    background-size: cover;
    padding: 0 1.5625em;

    .mat-dialog-content {
      max-height: 100vh;
    }

    .container {
      mat-divider {
        margin-bottom: 2.25em;
        border-color: rgba(255, 255, 255, .25);
      }
    }

    .nav-topbar {
      height: 75px;
      padding: 0;
      background: transparent;

      .mat-icon-button {
        margin-left: auto;
        color: white;
        width: auto !important;
      }
    }

    .small-topbar {
      .mat-icon-button {
        color: white;
      }
    }
  }

  .page-container {
    display: flex;
    flex-direction: column;
    background: white;
    height: 100%;
    position: relative;

    .top {
      margin: 0 0 1.25rem 0;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      flex-direction: column-reverse;

      #pageTitle {
        align-self: center;
        font-size: $default-font-size;
        color: #8C6E83;
        letter-spacing: 12px;
        text-transform: uppercase;
        opacity: 0.4;
        font-family: 'TTProstoSans-Regular', sans-serif;
      }

      button.mat-button {
        margin-left: auto;
        margin-bottom: .25rem;
        background-color: $primary-color;
        color: white;
      }
    }


    form {
      .input-row {
        min-height: 3.125em;
        display: grid;

        mat-form-field {
          &:first-child {
            margin-right: 1.25em;
          }
        }

        .full-width {
          width: 100%;
          margin-right: 0;
        }
      }

      .full {
        grid-template-columns: 100%;
      }

      .half {
        grid-template-columns: 50% 50%;
      }

      .thirds {
        grid-template-columns: 33.3% 33.3% 33.3%;
      }

      .address {
        padding: 2em 0;

        mat-form-field {
          &:first-child {
            margin-right: 0;
          }
        }
      }
    }

    .edit {
      align-self: flex-end;
      position: fixed;
      background-color: $primary-color;
      color: white;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.5625em;
      margin-right: 1.5625em;
      bottom: 0;
      right: 0;
    }

    #profileAvatar {
      border-radius: 100%;
      width: 7.5em;
      height: 7.5em;
      background-color: $third-color;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.125em;
      align-self: center;

      #image {
        width: 93%;
        border-radius: 100%;
      }
    }
  }

  #progress {
    .progressDivider {
      margin: 1rem 0;
    }

    .checkPhase {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #A962AC;
      border-radius: 10px;
      padding: .5rem;
      color: #FFF;
      font-weight: 700;
      font-size: .75rem;

      &:hover {
        cursor: pointer;
        filter: brightness(110%);
      }
    }

    .bars {
      .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
        background: transparent;
      }

      .bar {
        margin: 1rem 0;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        color: white;

        &:nth-child(even) {
          margin-right: 0;
        }

        .header {
          padding: 2.5rem 0.75rem;

          .mat-content {
            display: flex;
            flex-direction: column;

            .mat-progress-bar {
              width: 97%;
            }
          }

          mat-panel-title {
            margin-right: 0;
            color: white;
            display: flex;
            justify-content: space-between;

            .title {
              font-weight: bold;
            }

            .amount {
              display: flex;
            }
          }

          .achieved {
            font-size: .6125em;
            margin: .5rem 0 .25rem 0;
            color: rgba(255, 255, 255, .5)
          }
        }

        .mat-expansion-panel-content {
          color: #4D4D4D;
          background: white;

          .mat-expansion-panel-body {
            display: flex;
            flex-direction: column;
            margin-top: 1.5em;
            padding: 0 .6125em 1em;

            .averageGrade {
              align-self: center;
              margin-bottom: 1em;

              span {
                font-weight: 700;
              }
            }

            .row {
              .date {
                font-size: .6125em;
              }

              .test {
                display: flex;
                justify-content: space-between;

                .testName {
                  font-size: $default-font-size;
                }

                .testGrade {
                  font-size: 1.5em;
                  font-weight: 700;
                  color: rgba(81, 81, 81, 0.85);

                  &--notPassed {
                    color: #F44336;
                  }
                }
              }

              .teacher {
                font-size: .9em;
                opacity: 0.81;

                span {
                  opacity: 0.87;
                }
              }

              mat-divider {
                margin: .25em 0;
              }
            }

            .pagination {
              padding: .75em 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }
        }

        mat-progress-bar {
          height: .75em;
          border-radius: 1.25em;
          border: 2px solid white;

          .mat-progress-bar-buffer {
            background-color: rgba(255, 255, 255, 1);
          }
        }
      }
    }

    .wide {
      grid-template-columns: 1fr;
    }

    #information {
      .class, .cohort, .status, .optional_part {
        display: flex;
        flex-direction: column;
        text-align: center;

        .title {
          font-size: $default-font-size * .75;
          color: #515151;
          opacity: .55;
        }

        .content {
          opacity: .7;

          mat-icon {
            margin-left: auto;
            &:hover {
              cursor: pointer;
            }
          }
        }


        mat-divider {
          margin-top: .75em;
        }
      }
    }
  }

  #study, #locationTypes {
    border-radius: 10px;
    border: 1px solid $primary-color;
    box-shadow: none;

    mat-expansion-panel-header {
      justify-content: center;
      height: 2rem;
      border-radius: 10px;

      .mat-content {
        flex: inherit;
        align-self: center;

        mat-panel-title {
          color: $primary-color;
          margin-right: 0;
          font-weight: 700;
        }
      }

      .mat-expansion-indicator {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
    }

    mat-expansion-panel-header[aria-disabled=false] {
      .mat-content {
        margin-left: auto;
      }
    }

    .option {
      padding: .5rem .375em;
      color: $primary-color;
      text-align: center;
      margin-bottom: 0;
    }
  }
}
