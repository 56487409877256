@import "variables";

.fabButtonNoShadow {

  button {

    box-shadow: unset !important;
    background: linear-gradient(180deg, #BC569C 0%, rgba(255, 255, 255, 0) 190%), #D1419C !important;
  }

}
