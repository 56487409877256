@import 'breakpoints/mobile';
@import 'breakpoints/tablet';
@import 'breakpoints/landscape/mobile';
@import 'breakpoints/landscape/tablet';
@import 'breakpoints/portrait/mobile';
@import 'breakpoints/portrait/tablet';

@media (min-width: 1920px) {
  .topbar {
    .tablet_blob {
      width: 20%;
    }
  }

  .welcome {
    #medivus-logo {
      width: 30em;
    }
  }

  .landscape_bg {
    background-image: url("../../../assets/svgs/backgrounds/DesktopBG.svg") !important;
    background-position: 0px -150px !important;
  }

  .contentContainer {
    max-width: 60%;
    margin: 0 auto;
  }

  .pagination-buttons {
    width: 50%;
  }
}

@media (min-width: 2560px) {
  .content_area {
    .landscape_bg {
      height: 100%;
    }

    #profile {
      height: 20em;
    }
  }
}

#avatar, #profileAvatar {
  mat-icon {
    //width: 100%;
    color: #fafafa;
  }
}
