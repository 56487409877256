.previous-icon {
  color: #D095D4;
  font-size: 2.6rem;
  position: absolute;
  top: 35%;
  left: 1%;
}

.next-icon {
  color: #D095D4;
  font-size: 2.6rem;
  position: absolute;
  top: 35%;
  right: 1%;
}

@media screen and (min-width: 768px) {
  .previous-icon {
    top: 50%;
    transform: translateY(-50%);
  }

  .next-icon {
    top: 50%;
    transform: translateY(-50%);
  }
}


