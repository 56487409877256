// Shared responsive styles
@media #{$mq-small} {
  .topbar, .small-topbar, .nav-topbar {
    button {
      width: 75px;
      height: 75px;

      .mat-button-wrapper {
        .mat-icon {
          height: inherit;
          width: inherit;
          font-size: 36px;
        }
      }
    }
  }

  .welcome {
    padding: 0em 1.5em 3.5em 1.5em;

    #medivus-logo {
      width: 20em;
    }

    span, .welcome_mobile {
      font-size: $default-font-size * 3.25;
    }
  }

  #profile {
    .information {
      text-align: center;

      .name {
        font-size: 2.5em;
      }

      .mail {
        font-size: 1em;
      }
    }
  }

  .content_area {
    position: initial;
  }

  .portrait_bg {
    width: 100%;
  }

  .landscape_bg {
    background-position: 0px -90px !important;
  }

  .page-container {
    form {
      .input-row {
        mat-form-field {
          &:first-child {
            margin-right: 6%;
          }
        }
      }

      .address {
        mat-form-field {
          &:first-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .container {
    .example-viewport {
      margin: 3% auto 0 auto;
      height: 22em;
    }

    .buttons {
      display: grid;
      grid-template-columns: 120px 120px 120px 120px;
      grid-gap: 1.85em;
      width: auto;

      button {
        width: 120px;
        height: 120px;
        font-size: 0.65em;
      }
    }
  }

  // TODO fix importants
  .next-icon, .previous-icon {
    font-size: 5em !important;
  }

  .pagination-buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 20px;

    button {
      width: 50px !important;
      height: 50px !important;
      margin: 0 1em !important;
    }
  }
}

@media #{$mq-medium} {
  .container {
    .buttons {
      grid-template-columns: 150px 150px 150px 150px;

      button {
        width: 150px;
        height: 150px;
        font-size: .75em;
      }
    }
  }
}
