@import "../../_theme/mixins";
@import "../../_theme/variables";

// FIXME: Set correct colors
$left-bg: rgb(165, 91, 168);
$right-bg: rgba(165, 91, 168, .8);

.container-fluid {
  background: linear-gradient(to left, $right-bg 50%, $left-bg 50%);
  min-height: 100vh;

  @include query(from, xl) {

    .container {
      min-height: 100vh;
      align-items: center;
    }
  }
}

.stepper {
  box-shadow: 0 48px 60px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
  border-radius: $stepper-radius;

  .top-logo-mobile {
    display: none;
  }

  &__side {
    padding: 2.5rem;
    flex: 50%;

    &--left {
      background-color: #a55ba8;
      color: #FAFAFA;
    }

    &--right {
      background-color: #FAFAFA;
    }

    .title {
      color: #515151;
      font-size: 2.5rem;
      //font-family: $bold-font-family; // FIXME: Not correct weight
    }
  }

  @include query(until, lg) {
    height: 90vh;
    overflow: hidden;
    padding: 1rem 0;
    $stepper-radius: calc($stepper-radius / 2);
    box-shadow: none;
    justify-content: flex-end;

    .top-logo-desktop {
      display: none;
    }

    .top-logo-mobile {
      display: flex;
    }

    &__side {
      padding: 1.25rem;
      overflow: auto;

      &--left {
        border-radius: 0 0 $stepper-radius $stepper-radius;
        flex: .75;
        overflow: hidden;
      }

      &--right {
        //height: 55%;
        flex: 2;
        border-radius: $stepper-radius $stepper-radius 0 0;
      }

      .title {
        margin-bottom: 1.25rem;
        font-size: 1.25rem;
        text-align: center;
      }
    }
  }

  @include query(to, xs) {
    font-size: .75rem;

    &__side {
      &--left {

      }

      &--right {
        height: 65%;
      }
    }
  }

  // Form fields
  mat-hint {
    color: #515151;
    font-size: .625rem;
  }

  .mat-form-field-infix {
    border-top: 0 !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: #A55BA8;
    }

    .mat-form-field-outline-start, .mat-form-field-outline-end {
      border: 2px solid currentColor;
    }

    .mat-form-field-outline-start {
      min-width: 10px;
      border-radius: 10px 0 0 10px !important;
      border-right: none !important;
    }

    .mat-form-field-outline-end {
      border-radius: 0 10px 10px 0 !important;
      border-left: none !important;
    }
  }
  //

  .items {
    gap: .5rem;

    .cost-item {
      color: #667085;
      padding: 1rem;
      border-radius: 10px;
      border: 1px solid #D0D5DD;
      background: #FFFFFF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      span {
        font-weight: 700;
      }

      &--selectable {
        cursor: pointer;
      }

      &--notSelected {
        opacity: .55;
      }
    }
  }
}

.mat-flat-button {
  padding: .5rem 1rem !important;
  border-radius: 10px !important;
  background: #6CB8C9;
  font-size: 1.125rem;
  font-weight: 700;
  color: #ffffff;
  //min-width: 300px !important; // FIXME: Remove this later;

  @include query(to, xs) {
    padding: .25rem 1rem !important;
    font-size: .875rem;
  }
}
