// Portrait
@media #{$mq-small-portrait} {
  #profile {
    .information {
      text-align: center;
    }

    .name {
      font-size: $default-font-size * 2.5;
    }

    .mail {
      font-size: $default-font-size;
    }
  }

  app-sidebar {
    .container {
      .buttons {
        align-self: center;
      }
    }
  }

  .topbar {
    height: 10em;

    .logo {
      top: 3em;
      width: auto;
    }
  }

  .small-topbar {
    .blob {
      width: 20%;
    }
  }

  .content_area {

  }

  .next-icon {
    right: 30px !important;
  }

  .previous-icon {
    left: 30px !important;
  }
}

@media #{$mq-medium-portrait} {
  .content_area {

  }

  .topbar {
    height: 11em;

    .blob-top {
      width: 55%;
      margin-top: -6px;
      margin-left: -56px;
    }

    .blob-bottom {
      width: 60%;
    }

    .logo {
      top: 2.5em;
      width: 125px;
    }
  }

  .small-topbar {
    .blob {
      width: 12%;
    }
  }

  .welcome {
    #medivus-logo {
      width: auto;
    }

    span {
      font-size: 2.5em;
    }
  }

  #profile {
    #avatar {
      width: 20em;
      height: 20em;
    }
  }

  .small-topbar {
    .blob {
      width: 15%;
    }
  }

  .page-container {
    position: relative;
    height: fit-content;
    padding: 3em;

    form {
      margin-top: 2em;
    }

    .edit {
      width: 64px;
      height: 64px;
      margin: 0 1.5625em 1.5625em 0;
      right: 0;
    }

    #profileAvatar {
      width: 15em;
      height: 15em;

      #image {
        width: 93%;
      }
    }
  }
}


