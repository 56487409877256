.cdk-virtual-scroll-data-source-example .example-viewport {
  max-width: 768px;
  height: 14em;
  width: 80vw;
  margin: 2% auto 0 auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
}

.cdk-virtual-scroll-data-source-example .example-viewport::-webkit-scrollbar {
  display: none;
}

.cdk-virtual-scroll-data-source-example .example-viewport .cdk-virtual-scroll-content-wrapper {
  display: flex;
}

.cdk-virtual-scroll-data-source-example .example-item {
  max-width: 768px;
  width: 80vw;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.4em;
  position: relative;
  top: 0.5vh;
  margin-left: .8em;

  &:first-child {
    margin-left: 0;
  }
}

.pagination-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-buttons button {
  width: 1.75rem;
  height: 1.75rem;
  margin: 0 2%;

  &:hover {
    cursor: pointer;
  }
}

.previous-icon, .next-icon {
  &:hover {
    cursor: pointer;
  }
}

.drop-shadow {
  -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
}

.relative-position {
  position: relative;
}


@media screen and (max-width: 540px) {

  .cdk-virtual-scroll-data-source-example .example-item button {
    width: 90px;
    height: 90px;
  }

  .cdk-virtual-scroll-data-source-example .example-item button mat-icon {
    font-size: 50px;
  }
}

@media screen and (max-width: 375px) {
  .cdk-virtual-scroll-data-source-example .example-item:first-child {
    //left: 1%;
  }
}

@media screen and (min-width: 768px) {
  .cdk-virtual-scroll-data-source-example .example-viewport {
    margin: 2% auto 0 auto;
  }

  .cdk-virtual-scroll-data-source-example .example-item {
    grid-template-columns: repeat(4, 1fr);
  }

  .cdk-virtual-scroll-data-source-example .example-item button {
    height: 6rem;
    width: 6rem;
  }

  .cdk-virtual-scroll-data-source-example .example-item button mat-icon {
    font-size: 40px;
  }
}
