@import "responsive";
@import '../variables';

.development-phase-finished {
  display: flex;
  justify-content: center;

  h2 {
    margin-bottom: 0;
  }

  p {
    width: 70%;
  }

  .goButton {
    background-color: $primary-color;
    color: #fafafa;
    border-radius: 10px;
  }
}

.AddTopicComponentDialog {
  .page-container {
    padding: 0;
  }
}

// TODO: Add more reusable flex classes because these get used a lot.
// TODO: Put these into a separate .scss file
.flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
