// - COLORS
$primary-color: #A55BA8;
$secondary-color: #AE6BB1;
$third-color: #66ADAF;

// - SIZES
$default-small-topbar-height: 10%;
$default-topbar-height: 15%;

// - GRADIENTS
$gradient-1: linear-gradient(360deg, #873C8A -149.91%, rgba(255, 255, 255, 0) 36.3%), #C379C9;
$gradient-2: linear-gradient(180deg, #C379C9 -103.7%, rgba(255, 255, 255, 0) 163.57%), #522546;
$gradient-3: linear-gradient(180deg, #66ADAF 0%, rgba(255, 255, 255, 0) 190%), #66ADAF;
$gradient-4: linear-gradient(180deg, #BC569C 0%, rgba(255, 255, 255, 0) 190%), #D1419C;

// - PROGRESS BAR GRADIENTS
$pb-gradient-purple: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #A55BA8;
$pb-gradient-pink: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #BC569C;
$pb-gradient-green: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #467879;
$pb-gradient-brown: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #886980;

// - FONTS & SIZES
$default-font-size: 1em;

// - BREAKPOINTS
$bp-mobile: 20em; // 320px
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-ipad-air: 73.75em;
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-2k: 159em; // 2544px
$bp-4k: 239em; // 3824px

$multiplier-4k: 1.7;
$multiplier-2k: 1.2;

// - MEDIA QUERIES
$mq-mobile: "(min-width: #{$bp-mobile})";

$mq-small: "(min-width: #{$bp-small})";
$mq-small-portrait: "(min-width: #{$bp-small}) and (orientation: portrait)";
$mq-small-landscape: "(min-width: #{$bp-small}) and (orientation: landscape)";

$mq-medium: "(min-width: #{$bp-medium})";
$mq-medium-portrait: "(min-width: #{$bp-medium}) and (orientation: portrait)";
$mq-medium-landscape: "(min-width: #{$bp-medium}) and (orientation: landscape)";

$mq-ipad-air: "(min-width: #{$bp-ipad-air})";

$mq-large: "(min-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-2k: "(min-width: #{$bp-2k})";
$mq-4k: "(min-width: #{$bp-4k})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";
