@media (min-width: 320px) and (max-width: 374px) and (orientation: portrait) {
  .content_area {
    height: auto;
  }

  .welcome {
    #medivus-logo {
      width: 15em;
      height: 3.25em;
      padding: 0;
    }

    span, .welcome_mobile {
      font-size: $default-font-size * 1.5;
    }
  }


  mat-sidenav {
    .container {

    }
  }

  .container {
    .buttons {
      grid-template-columns: repeat(2, 1fr);
    }
  }

}

@media (min-width: 375px) and (max-width: 767px) and (orientation: portrait) {
  .container {
    .buttons { grid-template-columns: repeat(3, 1fr); }
  }
}
