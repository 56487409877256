.previous-icon {
  color: #D095D4;
  cursor: pointer;
  font-size: 2.6rem;
  position: absolute;
  top: 35%;
  left: 1%;
}

.next-icon {
  color: #D095D4;
  cursor: pointer;
  font-size: 2.6rem;
  position: absolute;
  top: 35%;
  right: 1%;
}
