// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

$regular-font: '"TTProstoSans-Regular", sans-serif';
$custom-typography: mat-typography-config( $font-family: $regular-font);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($custom-typography);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$medivus-student-frontend-primary: mat-palette($mat-indigo);
$medivus-student-frontend-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$medivus-student-frontend-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$medivus-student-frontend-theme: mat-light-theme($medivus-student-frontend-primary, $medivus-student-frontend-accent, $medivus-student-frontend-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($medivus-student-frontend-theme);

@import "themes/light-theme";

// Dark Mode //

$custom-primary: (
  main: #1565c0,
  light: #5e91f2,
  dark: #003b8e,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white,
  ));

$custom-accent: (
  main: #bb86fc,
  light: #efb7ff,
  dark: #8858c8,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white,
  ));

.dark-theme {
  color: $light-primary-text;
  $dark-primary: mat-palette($custom-primary, main, light, dark);
  $dark-accent: mat-palette($custom-accent, main, light, dark);
  $dark-warn: mat-palette($mat-red);
  $dark-theme: mat-dark-theme($dark-primary, $dark-accent, $dark-warn);

  @import "themes/dark-theme";

  @include angular-material-theme($dark-theme);
}
