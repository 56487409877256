// Landscape
@media #{$mq-small-landscape} {
  .landscape_bg {
    display: block;
  }

  .portait_bg {
    display: none;
  }
}

@media #{$mq-medium-landscape}  {
  #dashboard {
    margin: 2em 0;
  }

  .content_area {
    .behind, .front {
      height: 70%;
    }

    .welcome {
      span {
        display: none;
      }
    }
  }

  mat-sidenav {
    .container {
      .buttons {
        align-self: center;
      }
    }
  }

  .topbar {
    height: 8em;

    .tablet_blob {
      width: 35%;
    }

    .blob-top {
      width: 35%;
      margin-top: -6px;
    }

    .blob-bottom {
      width: 40%;
    }
  }

  .small-topbar {
    .blob {
      width: 15%;
    }
  }

  #profile {
    align-items: flex-start;
    background: none;
    flex-direction: row;
    padding-bottom: 0;
    height: 9em;

    .welcome_mobile {
      display: block;
      color: rgba(0,0,0, 0.2);
      font-size: $default-font-size * 2;
    }

    #avatar {
      margin-left: 5em;
      position: absolute;
      left: 0;
      top: -50px;
    }

    .information {
      align-self: flex-end;

      .name {
        font-size: $default-font-size * 2;
      }

      .mail {
        font-size: $default-font-size * 1;
      }
    }
  }

  .container {
    .buttons {
      grid-template-columns: repeat(4, 100px);

      button {
        width: 100px;
        height: 100px;
        font-size: .75em;
      }
    }
  }

  .next-icon, .previous-icon {
    top: 50% !important;
  }

  .next-icon {
    right: 62px !important;
  }

  .previous-icon {
    left: 62px !important;
  }
}

// Landscape iPad air
@media #{$mq-ipad-air} {
  .small-topbar {
    .blob {
      width: 12%;
    }
  }

  .content_area {
    .behind, .front {
      height: 80%;
      width: 100%;
    }
  }
}

// Landscape iPad Pro and up
@media #{$mq-large} {
  .content_area {
    #content_bg {
      bottom: 0;
    }

    .front, .behind {
      height: auto;
      width: auto;
    }
  }

  .welcome {
    padding: 2em 1.5em 0.5em 1.5em;
  }

  #profile {
    height: 15em;

    #avatar {
      width: 20em;
      height: 20em;
    }

    .information {
      align-self: flex-end;

      .name {
        font-size: $default-font-size * 2.5;
      }

      .mail {
        font-size: $default-font-size * 1.25;
      }
    }
  }

  .container {
    .buttons {
      grid-template-columns: 150px 150px 150px 150px;

      button {
        width: 160px;
        height: 160px;
        font-size: .8em;
      }
    }
  }

  .pagination-buttons {
    margin-bottom: 50px;
  }
}
