$breakpoints: (
  'xs': 375px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'hd': 1400px
);

/// Helper to clear inner floats
/// @author Nicolas Gallagher
/// @link http://nicolasgallagher.com/micro-clearfix-hack/ Micro Clearfix
@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

/// Helper to size an element
/// @author Kitty Giraudel
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin query($dir: from, $breakpoint: 375px) {
  $condition: min-width;
  @if ($dir == until or $dir == to) {
    $condition: max-width;
  }

  @media screen and (#{$condition}: map-get($breakpoints, #{$breakpoint})) {
    @content;
  }
}
