.internshipProgressBar .mat-progress-bar-fill::after {
  background: #A65BA8;
}

.testsProgressBar .mat-progress-bar-fill::after {
  background: #8C6E83;
}

.coursesProgressBar .mat-progress-bar-fill::after {
  background: #C362A4;
}

.choicePackagesProgressBar .mat-progress-bar-fill::after {
  background: #66ADAF;
}

.practiceProgressBar .mat-progress-bar-fill::after {
  background: #C379C9;
}

.theoryProgressBar .mat-progress-bar-fill::after {
  background: #CD6D8F;
}

.mat-progress-bar-fill::after {
  opacity: .6;
}
