// CSS Reset, comment out if not required or using a different module
//@import '~minireset.css/minireset';


/* You can add global styles to this file, and also import other style files */
@import './styles/theme';
@import "styles/_theme/buttons";
@import "~mdb-angular-ui-kit/assets/scss/mdb.scss";
@import "styles/_theme/mixins";

html, body {
  height: 100%;
}

body {
  font-family: 'TTProstoSans-Regular', sans-serif;
  margin: 0;
}

button.mat-menu-item {
  line-height: 24px !important;
}

a.mat-menu-item > mat-icon {
  margin-bottom: 14px;
}

.mat-icon svg {
  height: 24px;
  width: 24px;
}

.cdk-overlay-pane {
  transform: translateX(0) !important;
}

//.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
//  border: none;
//  background: #7B61FF;
//  color: #FFFFFF;
//}

.mat-calendar-body-selected {
  background: #7B61FF;
  color: #FFFFFF;
}

.mat-calendar-body-range-start {
  .mat-calendar-body-cell-content {
    border-radius: 999px 0 0 999px
  }
}


.mat-calendar-body-range-end {
  .mat-calendar-body-cell-content {
    border-radius: 0 999px 999px 0;
  }
}

.mat-calendar-body-in-range::before {
  background: #7B61FF;
  opacity: 0.6;
}

.custom-date-class {
  &:not(.mat-calendar-body-in-preview) {
    .mat-calendar-body-cell-preview {
      background: #EF8BFF;
      opacity: 0.75;
      border-radius: 100%;
    }
  }

  &--hoursSmall:not(.mat-calendar-body-in-preview) {
    .mat-calendar-body-cell-preview {
      height: 50%;
      width: 50%;
      top: 25%;
      left: 25%;
    }
  }

  &--hoursMedium:not(.mat-calendar-body-in-preview) {
    .mat-calendar-body-cell-preview {
      height: 65%;
      width: 65%;
      top: 18%;
      left: 18%;
    }
  }

  &--hoursLarge:not(.mat-calendar-body-in-preview) {
    .mat-calendar-body-cell-preview {
      height: 80%;
      width: 80%;
      left: 10%;
      top: 10%;
    }
  }
}

.custom-date-class.mat-calendar-body-active {
  .mat-calendar-body-cell-content {
    background: #EF8BFF;
  }
}

#weekNumberWrapper {
  display: flex;
  background-color: #ffffff;

  .mat-calendar-content {
    width: 100%;
    padding: .5rem;
  }

  #weekNumberContent {
    //padding-top: 2.75rem;
    border-right: solid 1px #eeeeee;
    color: #7B61FF;

    #weekNumberTable {
      td {
        font-size: .625rem;
      }
    }
  }
}

strong {
  font-family: "TTProstoSans-Bold", sans-serif;
}

@import "styles/global";
